import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { BodyHome } from '../components/layout/body/home';
import { CoverHome } from '../components/cover/home';
import { useDictionary } from '../context/dictionary';

export const query = graphql`
  query PageHomeQuery($id: String!, $locale: String!, $coverArticle: String) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageHome(id: { eq: $id }) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        ...BlockTopicsSelected
        ...BlockArticlesSelected
        ...BlockPromotion
        ...BlockArticlesLatest
        ...BlockShowsSelected
        ...BlockSubscribe
      }
      coverMedia{
        ...MediaCover
      }
      coverMediaPortrait{
        ...MediaCoverPortrait
      }
      coverArticle{
        ...Article
      }
      coverVariant
    }
    shows: allDatoCmsTvShow(sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: $locale}, slug: {ne: null}, thumbnail: {url: {ne: null}}}, limit: 3) {
      nodes {
        ...Show
      }
      totalCount
    }
    articles: allDatoCmsBlogArticle(sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: $locale}, slug: {ne: null}, thumbnail: {url: {ne: null}}, id: {ne: $coverArticle}}, limit: 19) {
      nodes {
        ...Article
      }
      totalCount
    }
    articlesByTopic: allDatoCmsBlogArticle(sort: {fields: meta___firstPublishedAt, order: DESC}, filter: {locale: {eq: $locale}, slug: {ne: null}, thumbnail: {url: {ne: null}}, id: {ne: $coverArticle}}) {
      group(field: topics___id,limit:4) {
        topic: fieldValue
        articles: nodes {
          ...Article
        }
        totalCount
      }
    }
  }
`;

const PageHome = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const page = prop('page', data);
  const articles = path(['articles','nodes'], data);
  const articlesByTopic = path(['articlesByTopic','group'], data);

  const showsCount = path(['shows','totalCount'], data);
  const articlesCount = path(['articles','totalCount'], data);

  const site = prop('site', data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const labelBlogAuthored = useDictionary("labelBlogAuthored");

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      {page.coverArticle && <CoverHome variant={page.coverVariant} page={page.coverArticle} heading={page.coverArticle.title} defaultMedia={page.coverMedia} portraitMedia={page.coverMediaPortrait} breadcrumb={page.coverArticle.topics[0]} date={page.coverArticle.meta.firstPublishedAtFormatted} caption={{label:labelBlogAuthored,value:page.coverArticle.authors}} />}
      {page.body && <BodyHome content={page.body} articlesCount={articlesCount} showsCount={showsCount} articles={articles} articlesByTopic={articlesByTopic} spaceBefore={true} spaceAfter={true} />}
    </Fragment>
  );
};

export default PageHome;