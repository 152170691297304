import styled from 'styled-components';
import { getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Heading = styled.h2`
  ${getTypography(Type.SECTION_HEADING)}
  margin-bottom: ${getUnitAsPixels({multiplier:1})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:2})};
  }
`;

export const Text = styled.div`
  ${getTypography(Type.BODY_2)}
  margin-bottom: ${getUnitAsPixels({multiplier:2})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;