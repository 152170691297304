import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from '../../../theme/layout';
import { BlockContainer } from '../container/container.component';
import { Container, Content, Illustration, IllustrationContainer } from './illustration.styles';
import { BlockIllustrationProps } from './illustration.types';

export const BlockIllustration = ({theme,illustration,children,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockIllustrationProps) => {
  return (
      <BlockContainer layout={Layout.GRID} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
        <ThemeProvider theme={theme}>
        <Container>
          <Content>
            {children}
          </Content>
          <IllustrationContainer>
            {illustration && <Illustration src={illustration.url} />}
          </IllustrationContainer>
        </Container>
        </ThemeProvider>
      </BlockContainer>
  )
};