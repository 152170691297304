import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useBrandTheme } from '../../../context/brand';
import { Layout, Size } from '../../../theme/layout';
import { CoverCaption } from '../caption';
import { CoverContainer } from '../container';
import { CoverMeta } from '../meta';
import { Container, Heading, Media, ContentContainer, Content, MediaContainer, HeadingLink, FooterContainer } from './home.styles';
import { CoverHomeProps, CoverVariant } from './home.types';

export const CoverHome = ({heading, breadcrumb, date, caption, defaultMedia, page}:CoverHomeProps) => {
  const theme = useBrandTheme("dark");

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 12},
    [Size.LARGE]: {span: 12},
    [Size.XLARGE]: {span: 12},
    [Size.MAX]: {span: 12},
  };

  return (
    <ThemeProvider theme={theme}>
      <CoverContainer layout={Layout.GRID}>
        <ContentContainer>
          <Content>
            <CoverMeta breadcrumb={breadcrumb} date={date} monochrome={true} />
            <Heading>{page ? <HeadingLink page={page} label={heading} /> : heading}</Heading>
          </Content>
        </ContentContainer>

        <MediaContainer>
          <Media defaultSource={defaultMedia} />
        </MediaContainer>

        {caption && <FooterContainer>
          <CoverCaption {...caption} />
        </FooterContainer>}
      </CoverContainer>
    </ThemeProvider>
  )
};