import React from 'react';
import { ButtonVariant } from '../../core/button/button.types';
import { ButtonLinks } from '../../core/buttonLinks';
import { BlockIllustration } from '../illustration';
import { Heading, Text } from './promotion.styles';
import { BlockPromotionProps } from './promotion.types';

export const BlockPromotion = ({theme,heading,text,illustration,buttonLinks,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockPromotionProps) => {
  return (
    <BlockIllustration theme={theme} illustration={illustration} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>
      {buttonLinks && <ButtonLinks links={buttonLinks} variant={ButtonVariant.SECONDARY} />}
    </BlockIllustration>
  )
};