import styled from 'styled-components';
import { getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const Container = styled.div`
  background-color: ${({theme}) => theme.background.hex};
  color: ${({theme}) => theme.primary.hex};
  overflow: hidden;
  grid-column: 1 / span 8;
  margin-left: ${getMarginAsPixels(Size.SMALL,{multiplier:-1})};
  margin-right: ${getMarginAsPixels(Size.SMALL,{multiplier:-1})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-left: ${getMarginAsPixels(Size.MEDIUM,{multiplier:-1})};
    margin-right: ${getMarginAsPixels(Size.MEDIUM,{multiplier:-1})};
    grid-column: 1 / span 12;
    display: flex;
    min-height: 360px;
  }

  ${getMediaQuery(Size.LARGE)}{
    margin-left: 0;
    margin-right: 0;
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-left: 0;
    margin-right: 0;
    grid-column: 4 / span 9;
  }
`;

export const Content = styled.div`
  flex: 1 0 50%;
  
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-inline-start: ${getMarginAsPixels(Size.SMALL,{multiplier:1})};
  padding-inline-end: ${getMarginAsPixels(Size.SMALL,{multiplier:1})};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-inline-start: ${getMarginAsPixels(Size.MEDIUM,{multiplier:1})};
    padding-inline-end: ${getMarginAsPixels(Size.MEDIUM,{multiplier:1})};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding: ${getSpacerAsPixels()} ${getSpacerAsPixels()};
  }
`;

export const IllustrationContainer = styled.div`
  position: relative;

  ${getMediaQuery(Size.MEDIUM)}{
    flex: 1 0 50%;
  }
`

export const Illustration = styled.img`
  display: block;
  width: 100%;
  height: ${getSpacerAsPixels({multiplier:4})};
  object-fit: cover;
  object-position: 50% 0;
  
  ${getMediaQuery(Size.MEDIUM)}{
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    object-position: 0 50%;
  }
`