import React from 'react';
import { Layout } from '../../../theme/layout';
import { TopicList } from '../../topic/topicList';
import { BlockContainer } from '../container/container.component';
import { Heading } from './topicsSelected.styles';
import { BlockTopicsSelectedProps } from './topicsSelected.types';

export const BlockTopicsSelected = ({heading,items,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockTopicsSelectedProps) => {
  return (
    <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
      <TopicList heading={heading} items={items} />
    </BlockContainer>
  )
};