import styled from "styled-components";
import { CONTAINER, getHeaderAsPixels, getLayout, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, GRID, Layout, Size, VerticalSpaceVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { LinkInternal } from "../../core/link";
import { ResponsiveMedia } from "../../core/responsiveMedia";
import ColorTransform from "color";
import { Color, getColor } from "../../../theme/color";

export const Container = styled.div`
  ${CONTAINER}
  position: relative;
  background-color: ${getColor(Color.BLACK)};
`

export const ContentContainer = styled.div`
  // ${CONTAINER}
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  display: flex;
  // align-items: flex-end;
  // align-content: flex-end;

  grid-column: 1 / span 8;
  
  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 1 / span 7;
  }

`

export const Heading = styled.h1`
  ${getTypography(Type.HEADLINE)}
  color: ${({theme}) => theme.primary.hex};
`;

export const HeadingLink = styled(LinkInternal)`
  color: ${({theme}) => theme.primary.hex};
  text-decoration: none;
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => ColorTransform(theme.primary.hex).isDark() ? ColorTransform(theme.primary.hex).mix(ColorTransform("white"), 0.3).toString() : ColorTransform(theme.primary.hex).mix(ColorTransform("black"), 0.3).toString()};
  }
`

export const MediaContainer = styled.div`
  position: relative;
  grid-column: 1 / span 8;
  
  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 9 / span 4;
  }
`

export const FooterContainer = styled.div`
  grid-column: 1 / span 8;
  
  
  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
   
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 1 / span 7;
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`

export const Media = styled(ResponsiveMedia)`

`

export const Content = styled.div`
  grid-column: 1 / span 8;
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};
  
  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 2 / span 11;
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 4 / span 9;
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`