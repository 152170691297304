import React, { useState } from 'react';
import { BlockIllustration } from '../illustration';
import { Button, Email, Heading, Message, Text, ErrorMessage } from './subscribe.styles';
import { BlockSubscribeProps, FormState } from './subscribe.types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Mailchimp } from '../../core/mailchimp';

export const BlockSubscribe = ({theme,heading,text,illustration,mailchimp,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockSubscribeProps) => {
  

  return (
    <BlockIllustration theme={theme} illustration={illustration} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>
      {mailchimp && <Mailchimp {...mailchimp} />}
    </BlockIllustration>
  )
};