import React from 'react';
import { Layout } from '../../../theme/layout';
import { ArticleSlider } from '../../article/articleSlider';
import { BlockContainer } from '../container/container.component';
import { BlockArticlesSelectedProps } from './articlesSelected.types';

export const BlockArticlesSelected = ({heading,totalCount,items,extraSpaceBefore,extraSpaceAfter,noSpaceBefore,noSpaceAfter,borderBefore}:BlockArticlesSelectedProps) => {
  return (
    <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} borderBefore={borderBefore}>
      <ArticleSlider items={items} heading={heading} />
    </BlockContainer>
  )
};