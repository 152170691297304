import { get } from 'lodash';
import React from 'react';
import { useBrandTheme } from '../../../../context/brand';
import { BlockArticlesLatest } from '../../../block/articlesLatest';
import { BlockArticlesSelected } from '../../../block/articlesSelected';
import { BlockPromotion } from '../../../block/promotion';
import { BlockSubscribe } from '../../../block/subscribe';
import { BlockShowsSelected } from '../../../block/showsSelected';
import { BlockTopicsSelected } from '../../../block/topicsSelected';
import { Container } from './home.styles';
import { BodyHomeProps } from './home.types';

const getThemeName = (block:any,fallback?:string):string | undefined => {
	const dark = useBrandTheme("dark");
	const light = useBrandTheme("light");
	const type = getTypeName(block);

	if(type === "block_shows_selected"){
		return dark.name;
	}
	
	if(type === "block_promotion" || type === "block_subscribe"){
		return light.name;
	}

	return get(block, "theme.name",fallback);
}

const getTypeName = (block:any,fallback?:string):string | undefined => {
	return get(block, "model.apiKey",fallback);
}

export const BodyHome = ({content,showsCount,articlesCount,articles,articlesByTopic,spaceBefore,spaceAfter}:BodyHomeProps) => {
  const pageTheme = useBrandTheme("light");
	
	let blockBefore;
	let blockAfter;
	let blockCurrent;

	let themeCurrent;
	let themeBefore;
	let themeAfter;
	
	let extraSpaceBefore = false;
	let extraSpaceAfter = false;
	let noSpaceBefore = false;
	let noSpaceAfter = false;
	
	let themeChangeBefore = false;
	let themeChangeAfter = false;

	let borderBefore;

	if(content){
		return (
			<Container>
				{content.map((block:any, index:number) => {
					blockBefore = content[index - 1];
					blockCurrent = content[index];
					blockAfter = content[index + 1];

					const type = getTypeName(blockCurrent);

					themeBefore = getThemeName(blockBefore,pageTheme.name);
					themeCurrent = getThemeName(blockCurrent,pageTheme.name);
					themeAfter = getThemeName(blockAfter,pageTheme.name);

					themeChangeBefore = blockBefore !== undefined && themeBefore !== themeCurrent;
					themeChangeAfter = blockAfter !== undefined && themeAfter !== themeCurrent;

					borderBefore = (!themeChangeBefore && index !== 0 && type !== "block_promotion" && type !== "block_subscribe" && type !== "block_topics_selected")

					extraSpaceBefore = (themeChangeBefore || (index === 0 && spaceBefore === true));
					extraSpaceAfter = (themeChangeAfter || (index === content.length - 1 && spaceAfter === true));
					
					switch(type){
						case 'block_topics_selected': return <BlockTopicsSelected key={index} {...block} borderBefore={borderBefore} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
            case 'block_articles_selected': return <BlockArticlesSelected key={index} {...block} totalCount={articlesCount} borderBefore={borderBefore} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
            case 'block_articles_latest': return <BlockArticlesLatest key={index} {...block} borderBefore={borderBefore} articles={articles} articlesByTopic={articlesByTopic} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_shows_selected': return <BlockShowsSelected key={index} {...block} totalCount={showsCount} borderBefore={borderBefore} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
            case 'block_promotion': return <BlockPromotion key={index} {...block} borderBefore={borderBefore} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
						case 'block_subscribe': return <BlockSubscribe key={index} {...block} borderBefore={borderBefore} extraSpaceBefore={extraSpaceBefore} extraSpaceAfter={extraSpaceAfter} noSpaceBefore={noSpaceBefore} noSpaceAfter={noSpaceAfter} />;
					}
					
					return null;
				})}
			</Container>
		)
	}
	return null
};